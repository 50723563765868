import './CostSummary.css'

const CostSummary = (props) => {


    const ListItem = (props) => {
        // console.log(props);
        return (

            <li className='cost-summary__line'>
                <dt>{props.description}</dt>
                <dd><span>€{(Number(props.price).toLocaleString("nl-NL", {maximumFractionDigits: 0}))}</span></dd>
            </li>);
    };

    return (
        <div>
            <h2>Cost summary</h2>
            <p>chosen items:</p>
            <ul>
                {Object.entries(props.items).map(([_key, v]) => {
                    return (
                        <ListItem
                            description={v.description}
                            productId={v.productId}
                            price={v.price}
                            key={v.productId}
                        />
                    )
                })
                }
            </ul>
            <ul>
                <li className='cost-summary__line' key='total'>
                    <dt>Total</dt>
                    <dd><span>€{(Number(props.orderTotal).toLocaleString("nl-NL", {maximumFractionDigits: 0}))}</span></dd>
                </li>
            </ul>
        </div>
    );
}
export default CostSummary;