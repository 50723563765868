import { Fragment, useState, useEffect, useRef } from 'react';

import "./App.css";

import Login from './components/Login/Login';
import Wizard from './components/Wizard/Wizard';
import MainHeader from './components/MainHeader/MainHeader';


function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const customerRef = useRef('');

  useEffect(() => {

    const userLoggedIn = localStorage.getItem('isLoggedIn');
    if (userLoggedIn === 'true') {
      // setIsLoggedIn(true);
      setIsLoggedIn(true);
    }
  }, []);

  const loginHandler = (customerName) => {
    customerRef.current = customerName;
    localStorage.setItem('isLoggedIn', 'true');
    setIsLoggedIn(true);
    //temp!!!
    localStorage.removeItem('isLoggedIn');

  }

  const logoutHandler = () => {
    localStorage.removeItem('isLoggedIn');
    // customerRef.current = null;
    setIsLoggedIn(false);
  };


  return (
    <Fragment>
      <MainHeader isAuthenticated={isLoggedIn} onLogout={logoutHandler} />
      <main className='main'>
        {!isLoggedIn && <Login onLogin={loginHandler} />}
        {isLoggedIn && <Wizard customer={customerRef.current} />}
      </main>
      
    </Fragment>
  );
}

export default App;
