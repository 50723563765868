import {Fragment, useEffect, useRef, useState} from 'react';

import './Wizard.css';
import Card from '../UI/Card/Card';
import Button from '../UI/Button/Button';
import CostSummary from '../CostSummary/CostSummary';
import ChargingStation from '../ChargingStation/ChargingStation';
import BasicChoice from '../Question/BasicChoice/BasicChoice';
import BasicDropdown from "../Question/BasicDropdown/BasicDropdown";

import VestelImage from '../../assets/images/vestel-black.png';
import AlfenImage from '../../assets/images/alfen-white.png';

const AUDIT_LINK = "https://app.acc.sparkwise.io/compliance-center?query=";
const API_KEY = "PBy51WWrU5pkLnGIQsd-H6IUSnyGzli7NbQ6GfMzyljmC320IThI7_PWCsKjBxlN";
const chargingStations = [{
    productId: "CS001",
    name: "Vestel EVC04 Entry",
    description: "7,44 kW 1Fase/16A",
    imageUrl: VestelImage,
},
    {
        productId: "CS002",
        name: "Vestel EVC04 Basic",
        description: "22 kW 3Fase/32A",
        imageUrl: VestelImage,
    },
    {
        productId: "CS003",
        name: "Alfen Eve Single Pro-Line",
        description: "22 kW 3Fase/32A",
        imageUrl: AlfenImage,
    }];

const findPrice = (modelOutput, productId) => {

    let price = 99999;
    const outputs = modelOutput.outputs;

    switch (productId) {
        case "CS001":
            price = outputs.PriceVestelEvc04Entry;
            break;
        case "CS002":
            price = outputs.PriceVestelEvc04Basic;
            break;
        case "CS003":
            price = outputs.PriceAlfenEveSingleProLine;
            break;
        default:
            throw new Error('missing productId mapping');
    }
    return price;
};

const Wizard = (props) => {

    const [leadConfig, setLeadConfig] = useState({});
    const lastSparkwiseResponse = useRef({});
    const [allowedChargingStations, setAllowedChargingStations] = useState([]);
    const [chosenItems, setChosenItems] = useState([]);
    const [orderTotal, setOrderTotal] = useState(0);
    const [billLeaseCompany, setBillLeaseCompany] = useState(0);
    const [billDriver, setBillDriver] = useState(0);

    const [finishResult, setFinishResult] = useState('');

    // installation state
    const [distance, setDistance] = useState('')
    const [pavement, setPavement] = useState('')
    const [substrate, setSubstrate] = useState('')

    // audit/execution links
    const [executionIdLeadConfig, setExecutionIdLeadConfig] = useState('');
    const [executionIdInstallationCosts, setExecutionIdInstallationCosts] = useState('');
    const [executionIdSplitBilling, setExecutionIdSplitBilling] = useState('');

    useEffect(() => {
        const total = Object.entries(chosenItems)
            .map(([_key, val]) => Number(val.price) ?? 0)
            .reduce((prev, next) => prev + next, 0);
        setOrderTotal(total);
    }, [chosenItems]);


    useEffect(() => {
        // https://react.dev/learn/synchronizing-with-effects#how-to-handle-the-effect-firing-twice-in-development
        let ignore = false;

        const fetchLeadConfig = async (customer) => {

            //ACC env Sparkwise (Azure endpoint)
            const url = "https://eacct7m238v1enecoleadconfiguration-fa.azurewebsites.net/api/exec";
            const reqBody = {Customer: customer};

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-functions-key': API_KEY
                },
                body: JSON.stringify(reqBody),
            });
            if (!response.ok) {
                console.log(response);
            }

            // setLeadConfig(modelOutput);
            if (!ignore) {
                const modelOutput = await response.json();
                setLeadConfig(modelOutput);
                lastSparkwiseResponse.current = modelOutput;
                setExecutionIdLeadConfig(modelOutput.meta.executionID);

                const allowedProducts = modelOutput.outputs?.AllowedProductIds.split(';');
                // const allowedProducts = ["CS001", "CS003"];
                const allowedChargingStations = chargingStations
                    .filter(item => allowedProducts.includes(item.productId))
                    .map(item => ({...item, price: findPrice(modelOutput, item.productId)}));

                // console.log('allowed charging stations:', allowedChargingStations);
                setAllowedChargingStations(allowedChargingStations);
            }
        };

        fetchLeadConfig(props.customer);

        // cleanup (avoid having multiple network result calls updating
        return () => {
            ignore = true;
        };

    }, [props.customer]);

    const selectedCSHandler = ({productId, chargingStation, price}) => {
        // replace for each question-group, to update the Cost summary?
        setChosenItems((prevState) => (
            {
                ...prevState,
                chargingStation: {price: price, description: chargingStation, productId: productId}
            }
        ));
    };

    const changeItemHandler = (productKeyName, productId, description, productPrice) => {
        // console.log('changeItemHandler received:', productKeyName, productId, description, productPrice);
        setChosenItems((prevState) => (
            {
                ...prevState,
                [productKeyName]: {price: Number(productPrice), description: description, productId: productId}
            }
        ));
    };


    useEffect(() => {
        // https://react.dev/learn/synchronizing-with-effects#how-to-handle-the-effect-firing-twice-in-development
        let ignore = false;
        if (distance === '' || pavement === '' || substrate === '') {
            // console.log('not all installation params are available, skipping calculation');
            return;
        }

        const calcInstallation = async (distance, pavement, substrate) => {
            //ACC env Sparkwise (Azure endpoint)
            const url = "https://eacct7m239v1enecocalculateinstallationcost-fa.azurewebsites.net/api/exec";
            const reqBody = {
                DistanceDescription: distance,
                Pavement: pavement,
                Substrate: substrate
            };

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-functions-key': API_KEY
                },
                body: JSON.stringify(reqBody),
            });
            if (!response.ok) {
                console.log(response);
            }

            if (!ignore) {
                const modelOutput = await response.json();
                lastSparkwiseResponse.current = modelOutput;
                setExecutionIdInstallationCosts(modelOutput.meta.executionID);

                setChosenItems((prevState) => (
                    {
                        ...prevState,
                        installation: {
                            "price": modelOutput.outputs.InstallationCost,
                            "description": "Installation (calculated)",
                            "productId": "1"
                        }
                    }

                ));
            }
        };

        calcInstallation(distance, pavement, substrate);

        // cleanup (avoid having multiple network result calls updating
        return () => {
            ignore = true;
        };

    }, [distance, pavement, substrate]);

    useEffect(() => {
        let ignore = false;
        if (orderTotal === 0) {
            return;
        }

        const fetchSplitBilling = async (customer, totalAmount) => {
            //ACC env Sparkwise (Azure endpoint)
            const url = "https://eacct7m240v2enecosplitbilling-fa.azurewebsites.net/api/exec";
            const reqBody = {Customer: customer, OrderTotal: totalAmount};

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-functions-key': API_KEY
                },
                body: JSON.stringify(reqBody),
            });
            if (!response.ok) {
                console.log(response);
            }

            if (!ignore) {
                const modelOutput = await response.json();
                setExecutionIdSplitBilling(modelOutput.meta.executionID);
                setBillDriver(modelOutput.outputs.BillDriver ?? 0);
                setBillLeaseCompany(modelOutput.outputs.BillLeaseCompany ?? 0);
                lastSparkwiseResponse.current = modelOutput;
            }
        };

        fetchSplitBilling(props.customer, orderTotal);

        // cleanup (avoid having multiple network result calls updating
        return () => {
            ignore = true;
        };

    }, [props.customer, orderTotal]);


    const submitForm = (event) => {
        event.preventDefault();
        const form = event.target;
        const data = {};

        // fixed items
        data["customer"] = props.customer;
        data["executions"] = {
            LeadConfig: executionIdLeadConfig,
            InstallationCosts: executionIdInstallationCosts,
            SplitBilling: executionIdSplitBilling
        };
        data["orderTotal"] = orderTotal;
        data[`billLeaseCompany`] = billLeaseCompany;
        data["billDriver"] = billDriver;
        data["items"] = chosenItems

        for (let i = 0; i < form.elements.length; i++) {
            const elem = form.elements[i];
            if (elem.name !== '') {
                data[elem.name] = elem.value;
            }
        }

        testApi(data).then();

        // document.querySelector('#name').textContent = text;
    };

    const testApi = async (data) => {
        const {text} = await (await fetch(`https://enecomobilitybackendapp.azurewebsites.net/api/OrderHttpTrigger`, {
            method: 'POST',
            body: JSON.stringify(data)
        })).json();
        setFinishResult("Result from backend: " + text);
    };


    return (
        <Fragment>
            <form id="registerForm" onSubmit={submitForm}>
                <Card className="wizard">
                    <BasicChoice
                        questionLine='Select a charging dock'
                    />
                    <ul id="concepts">
                        {allowedChargingStations.map((cs) => <ChargingStation
                            key={cs.productId}
                            productId={cs.productId}
                            name={cs.name}
                            description={cs.description}
                            imageUrl={cs.imageUrl}
                            price={cs.price}
                            onSelected={selectedCSHandler}
                        />)}
                    </ul>
                </Card>
                <Card className="wizard">
                    <BasicChoice questionLine='Cable *'
                                 subLine='Do you want the charging point with or without fixed cable?'
                                 name='cable'
                                 opts={[
                                     {
                                         id: 1,
                                         caption: '3 meter',
                                         value: '3 meter',
                                         price: leadConfig?.outputs?.Price3M ?? 0
                                     },
                                     {
                                         id: 2,
                                         caption: '5 meter',
                                         value: '5 meter',
                                         price: leadConfig?.outputs?.Price5M ?? 0
                                     },
                                     {
                                         id: 3,
                                         caption: '7,5 meter',
                                         value: '7,5 meter',
                                         price: leadConfig?.outputs?.Price75M ?? 0
                                     }

                                 ]}
                                 onSelected={changeItemHandler}
                    />
                    <BasicChoice questionLine='Mounting'
                                 subLine='Do you want to mount the charging station on an outside wall or pole?'
                                 name='mounting'
                                 opts={
                                     [
                                         {'id': 1, 'caption': 'Wall', 'value': "Wall"},
                                         {'id': 2, 'caption': 'Pole', 'value': "Pole"}
                                     ]
                                 }
                    />
                    <BasicChoice questionLine='Load balancing'
                                 subLine='Load balancing ensures that your groups are not overloaded; for example, you switch on the oven while your car is charging.'
                                 name='loadBalancing'
                                 opts={
                                     [
                                         {'id': 1, 'caption': 'Yes', 'value': "Yes"},
                                         {'id': 2, 'caption': 'No', 'value': "No"}
                                     ]
                                 }
                    />
                    <BasicChoice questionLine='Subscriptions *'
                                 subLine='Choose a hosting subscription'
                                 name='subscriptions'
                                 opts={
                                     [
                                         {
                                             'id': 1,
                                             'caption': 'Service and maintenance',
                                             'value': "Service and maintenance"
                                         },
                                         {
                                             'id': 2,
                                             'caption': 'Service and maintenance light',
                                             'value': "Service and maintenance light"
                                         }
                                     ]
                                 }
                    />
                    <BasicChoice questionLine='Charging on the go *'
                                 subLine='Do you want to charge card or key ring?'
                                 name='chargingOtg'
                                 opts={
                                     [
                                         {'id': 1, 'caption': 'Eneco Charge Card', 'value': "Eneco Charge Card"},
                                         {'id': 2, 'caption': 'Eneco Charge Token', 'value': "Eneco Charge Token"}
                                     ]
                                 }
                    />
                    <BasicChoice questionLine='Charge card subscription *'
                                 subLine='Do you want to charge card or key ring?'
                                 name='chargingSub'
                                 opts={
                                     [
                                         {'id': 1, 'caption': 'Eneco Charge Card', 'value': "Eneco Charge Card"},
                                         {'id': 2, 'caption': 'Eneco Charge Token', 'value': "Eneco Charge Token"}
                                     ]
                                 }
                    />
                    {/*Installation*/}
                    <BasicChoice questionLine='Do you wish to installation by Eneco? *'
                                 subLine=''
                                 name='performInstallation'
                                 opts={
                                     [
                                         {'id': 1, 'caption': 'Yes', 'value': "Yes"},
                                         {
                                             'id': 2,
                                             'caption': 'No, I will arrange the installation myself',
                                             'value': "No, I will arrange the installation myself"
                                         }
                                     ]
                                 }
                    />
                    <BasicChoice questionLine='Meters of excavation required'
                                 subLine='The number of meters of excavation from the location of the charger to the fuse box.'
                                 name='excavationMeters'
                                 onSelected={(ret1, ret2, ret3, ret4, ret5) => {
                                     setDistance(ret5);
                                 }}
                                 opts={
                                     [
                                         {'id': 1, 'caption': '0-1m', 'value': '0-1m'},
                                         {'id': 2, 'caption': '2-5m', 'value': '2-5m'},
                                         {'id': 3, 'caption': '6-10m', 'value': '6-10m'},
                                         {'id': 4, 'caption': '11-15m', 'value': '11-15m'},
                                         {'id': 5, 'caption': 'More than 15m', 'value': 'More than 15m'},
                                     ]
                                 }
                    />

                    <BasicDropdown questionLine='Main type of pavement'
                                   subLine=''
                                   name='typeOfPavement'
                                   onSelected={(item) => {
                                       setPavement(item);
                                   }}
                                   opts={
                                       [
                                           {'id': 1, 'caption': 'Bricks/tiles', 'value': 'Bricks/tiles'},
                                           {'id': 2, 'caption': 'Tiles', 'value': 'Tiles'},
                                           {'id': 3, 'caption': 'Gravel/shells', 'value': 'Gravel/shells'},
                                           {'id': 4, 'caption': 'Other', 'value': 'Other'},
                                       ]
                                   }
                    />
                    <BasicDropdown questionLine='Main type of substrate'
                                   subLine=''
                                   name='typeOfSubstate'
                                   onSelected={(item) => {
                                       setSubstrate(item);
                                   }}
                                   opts={
                                       [
                                           {'id': 1, 'caption': 'Sand/ground', 'value': 'Sand/ground'},
                                           {'id': 2, 'caption': 'Clay', 'value': 'Clay'},
                                           {
                                               'id': 3, 'caption': 'Compressed construction waste',
                                               'value': 'Compressed construction waste'
                                           },
                                           {'id': 4, 'caption': 'Concrete', 'value': 'Concrete'},
                                           {'id': 5, 'caption': 'Other', 'value': 'Other'},
                                       ]
                                   }
                    />
                    <BasicChoice questionLine='Is the space of the fuse box adjacent to the outside wall?'
                                 subLine=''
                                 name='fuseboxAdjacent'
                                 opts={
                                     [
                                         {
                                             'id': 1,
                                             'caption': 'Yes, it is adjacent to the outer wall',
                                             'value': 'Yes, it is adjacent to the outer wall'
                                         },
                                         {
                                             'id': 2,
                                             'caption': 'No, it borders on a partition wall',
                                             'value': 'No, it borders on a partition wall'
                                         },
                                     ]
                                 }
                    />
                    <BasicChoice questionLine='Indicate what is correct for your crawl space'
                                 subLine='My crawl space is'
                                 name='crawlSpace'
                                 opts={
                                     [
                                         {'id': 1, 'caption': 'Dry', 'value': 'Dry'},
                                         {'id': 2, 'caption': 'Moist', 'value': 'Moist'},
                                         {'id': 3, 'caption': 'Isolation material', 'value': 'Isolation material'},
                                         {'id': 4, 'caption': 'Not accessible', 'value': 'Not accessible'},
                                     ]
                                 }
                    />
                    <BasicDropdown questionLine='Your connection'
                                   subLine='The connection value is the maximum amount of electricity that a house can supply at the same time. The connection value determines the charging speed of your charging point.'
                                   name='connection'
                                   opts={
                                       [
                                           {'id': 1, 'caption': '1 x 20A', 'value': '1 x 20A'},
                                           {'id': 2, 'caption': '1 x 25A', 'value': '1 x 25A'},
                                           {'id': 3, 'caption': '1 x 30A', 'value': '1 x 30A'},
                                           {'id': 4, 'caption': '1 x 35A', 'value': '1 x 35A'},
                                       ]
                                   }
                    />
                </Card>

                <Card className="cost-summary fixedContainer">
                    <CostSummary items={chosenItems} orderTotal={orderTotal}/>
                    <br/>
                    <p>Split billing:</p>
                    <ul>
                        <li className='cost-summary__line'>
                            <dt>Bill leasecompany</dt>
                            <dd>
                                <span>€{(Number(billLeaseCompany).toLocaleString("nl-NL", {maximumFractionDigits: 0}))}</span>
                            </dd>
                        </li>
                        <li className='cost-summary__line'>
                            <dt>Bill driver</dt>
                            <dd><span>€{(Number(billDriver).toLocaleString("nl-NL", {maximumFractionDigits: 0}))}</span>
                            </dd>
                        </li>
                    </ul>
                </Card>
                <Button type="submit">Finish configuration</Button>

            </form>
            <p id="name">{finishResult}</p>
            <br/>
            <h3>Audit executions:</h3>
            <ul>
                {executionIdLeadConfig ?
                    <li><a className="auditLink" href={AUDIT_LINK + executionIdLeadConfig} target="_blank"
                           rel="noreferrer">Lead
                        configuration</a></li> : ''}
                {executionIdInstallationCosts ?
                    <li><a className="auditLink" href={AUDIT_LINK + executionIdInstallationCosts} target="_blank"
                           rel="noreferrer">Calculate
                        installation costs</a></li> : ''}
                {executionIdSplitBilling ?
                    <li><a className="auditLink" href={AUDIT_LINK + executionIdSplitBilling} target="_blank"
                           rel="noreferrer">Split
                        billing</a></li> : ''}
            </ul>
            <h4>last Sparkwise response:</h4>
            <pre>{JSON.stringify(lastSparkwiseResponse.current, null, 3)}</pre>
        </Fragment>
    );
};

export default Wizard;
