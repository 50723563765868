import VestelImage from '../../assets/images/vestel-black.png';
import AlfenImage from '../../assets/images/alfen-white.png';
import Button from '../UI/Button/Button';

import Card from '../UI/Card/Card';
import classes from './Login.module.css';

const Login = (props) => {
    const clickHandler = (event) => {
        console.log('click event.target.value=>', event.target.value);
        props.onLogin(event.target.value);
    }

    return (
        <Card className={classes.login}>
            <div>
                <Button  value="MHC" onClick={clickHandler}>Login as MHC driver</Button>
            </div>
            <div >
                <Button value="Company ABC - XYZ" onClick={clickHandler}>Login as default driver</Button>
            </div>
            <img src={VestelImage} style={{display: 'none'}} alt='pre1'/>
            <img src={AlfenImage} style={{display: 'none'}} alt='pre2'/>
        </Card>
    );
}

export default Login;