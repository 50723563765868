import './BasicDropdown.css'
// import {Fragment} from 'react';

const BasicDropdown = (props) => {
    const items = props.opts ?? [];
    const selectHandler = (event) => {
        // console.log('selectHandler basicdropdown event:' + event.target.value);
        if (props.onSelected) {
            props.onSelected(event.target.value);
        }
    };
    return (
        <div>
            <br/>
            <h3>{props.questionLine}</h3>
            <p className="sub-line">{props.subLine}</p>
            <select
                name={props.name}
                className="question-select"
                defaultValue="DEFAULT"
                onChange={selectHandler}>
                <option key="0" value="DEFAULT" disabled hidden>{props.questionLine}</option>
                {items.map(x => <option key={x.id} value={x.value}>{x.caption}</option>)}
            </select>
        </div>
    );
}
export default BasicDropdown;