import "./ChargingStation.css";
import Button from '../UI/Button/Button'
let selectedStation = '';

const ChargingStation = (props) => {

    const selectHandler = (event) => {
        selectedStation = event.target.value;
        // notify wizard
        props.onSelected(({
            productId: props.productId, //event.target.value,
            chargingStation: props.name,
            price: props.price
        }));
    };

    return (
        <li className='concept'

            key={props.productId}
            style={({'backgroundColor': selectedStation === props.productId ? 'orange' : 'transparent'})
            }>
            <div className="no-select" onClick={ () => selectHandler({target: { value: props.productId}})}>
                <h3>{props.name}</h3>
                <img src={props.imageUrl} alt={props.name} draggable="false"/>
                <h4>{props.description}</h4>
                <p>€{ (Number(props.price).toLocaleString("nl-NL", {minimumFractionDigits: 0}))}</p>
                <Button
                    // type="button"
                    className="chooseButton"
                    value={props.productId}
                    onClick={selectHandler}>Kies</Button>
            </div>
        </li>
    );
};

export default ChargingStation;