import './BasicChoice.css'
// import {useState} from 'react';

const BasicChoice = (props) => {
    const items = props.opts ?? [];
    const orientationClass = props.mode === "vertical" ? 'question-vertical' : 'question-horizontal';
    // const [selectedText, setSelectedText] = useState();
    const getPrice = (id) => {
        // console.log('getPrice for id:', id, JSON.stringify(items));

        return items.find(x => x.id === Number(id)).price ?? 0;
    };

    const selectHandler = (event) => {
        const target = event.target;

        // console.log('selectHandler basicchoice event:' + event.target.value);
        if (props.onSelected) {
            props.onSelected(props.name, '', `${props.name}: ${target.parentElement.innerText}`, getPrice(target.id), target.parentElement.innerText);
        }
    };
    return (
        <div>
            <br/>
            <h3>{props.questionLine}</h3>
            <p className="sub-line">{props.subLine}</p>
            <ul className="question-list" onChange={selectHandler}>
                {
                    items.map(item =>
                        <li key={item.id} className={orientationClass}>
                            <label className="option-label">
                                <input
                                    type="radio"
                                    value={item.value}
                                    name={props.name}
                                    id={item.id}
                                    className="option-radio"
                                />{item.caption}
                            </label>
                        </li>)
                }
            </ul>
        </div>
    );
}
export default BasicChoice;